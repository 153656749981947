export const appHead = {"link":[{"rel":"icon","href":"/favicon.svg","type":"image/svg+xml","sizes":"any"},{"rel":"icon","href":"/favicon-96x96.png","type":"image/png","sizes":"96x96"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","sizes":"180x180"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"apple-mobile-web-app-title","content":"nowoczesna stodoła"},{"property":"og:type","content":"website"}],"style":[],"script":[{"tagPriority":99999999,"innerHTML":"\n            var head = document.getElementsByTagName('head')[0];\n            // Save the original method\n            var insertBefore = head.insertBefore;\n\n            // Replace it!\n            head.insertBefore = function (newElement, referenceElement) {\n              if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com') > -1) {\n                return;\n              }\n\n              insertBefore.call(head, newElement, referenceElement);\n            };\n          "}],"noscript":[],"htmlAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false