
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46uri_9328X31qNNOMMeta } from "/src/pages/[...uri].vue?macro=true";
import { default as _91slug_93IELoqQ1TSAMeta } from "/src/pages/[type]/[slug].vue?macro=true";
import { default as indexCJtWNcAPuRMeta } from "/src/pages/[type]/index.vue?macro=true";
import { default as consentsLeThuKoHw9Meta } from "/src/pages/account/consents.vue?macro=true";
import { default as settingsVZPN3xkO33Meta } from "/src/pages/account/settings.vue?macro=true";
import { default as accountqjnKNJeI6iMeta } from "/src/pages/account.vue?macro=true";
import { default as _91id_936KNKlIMqVsMeta } from "/src/pages/boards/[id].vue?macro=true";
import { default as index5MfrMEy3B1Meta } from "/src/pages/boards/index.vue?macro=true";
import { default as indext2d5rnc5eWMeta } from "/src/pages/contact/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as privacyThqmx5VZe0Meta } from "/src/pages/legal/privacy.vue?macro=true";
import { default as termsgAcgp3eqFdMeta } from "/src/pages/legal/terms.vue?macro=true";
import { default as verify_45emailYTPRwYkxfTMeta } from "/src/pages/verify-email.vue?macro=true";
import { default as verify_45reset5hPxMmix4BMeta } from "/src/pages/verify-reset.vue?macro=true";
import { default as component_45stubkXhZKcJnWLMeta } from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubkXhZKcJnWL } from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "uri___pl",
    path: "/:uri(.*)*",
    component: () => import("/src/pages/[...uri].vue")
  },
  {
    name: "type-slug___pl",
    path: "/:type()/:slug()",
    component: () => import("/src/pages/[type]/[slug].vue")
  },
  {
    name: "type___pl",
    path: "/:type()",
    meta: indexCJtWNcAPuRMeta || {},
    component: () => import("/src/pages/[type]/index.vue")
  },
  {
    name: "account___pl",
    path: "/konto",
    component: () => import("/src/pages/account.vue"),
    children: [
  {
    name: "account-consents___pl",
    path: "zgody",
    component: () => import("/src/pages/account/consents.vue")
  },
  {
    name: "account-settings___pl",
    path: "ustawienia",
    component: () => import("/src/pages/account/settings.vue")
  }
]
  },
  {
    name: "boards-id___pl",
    path: "/tablice/:id()",
    component: () => import("/src/pages/boards/[id].vue")
  },
  {
    name: "boards___pl",
    path: "/tablice",
    component: () => import("/src/pages/boards/index.vue")
  },
  {
    name: "contact___pl",
    path: "/kontakt",
    component: () => import("/src/pages/contact/index.vue")
  },
  {
    name: "index___pl",
    path: "/",
    meta: indexvsa0KwJWqiMeta || {},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "legal-privacy___pl",
    path: "/dokumenty/prywatnosc",
    component: () => import("/src/pages/legal/privacy.vue")
  },
  {
    name: "legal-terms___pl",
    path: "/dokumenty/regulamin",
    component: () => import("/src/pages/legal/terms.vue")
  },
  {
    name: "verify-email___pl",
    path: "/verify-email",
    component: () => import("/src/pages/verify-email.vue")
  },
  {
    name: "verify-reset___pl",
    path: "/verify-reset",
    component: () => import("/src/pages/verify-reset.vue")
  },
  {
    name: component_45stubkXhZKcJnWLMeta?.name,
    path: "/zbuduj-lub-kup/:pathMatch(.*)",
    component: component_45stubkXhZKcJnWL
  },
  {
    name: component_45stubkXhZKcJnWLMeta?.name,
    path: "/mini-domki/:pathMatch(.*)",
    component: component_45stubkXhZKcJnWL
  },
  {
    name: component_45stubkXhZKcJnWLMeta?.name,
    path: "/do-wynajecia/:pathMatch(.*)",
    component: component_45stubkXhZKcJnWL
  },
  {
    name: component_45stubkXhZKcJnWLMeta?.name,
    path: "/materialy/:pathMatch(.*)",
    component: component_45stubkXhZKcJnWL
  },
  {
    name: component_45stubkXhZKcJnWLMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubkXhZKcJnWL
  }
]