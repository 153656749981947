import revive_payload_client_qd6qQR1ezm from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gjKQVggGEZ from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7JxBUxWU3o from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_lJzV5AbHuJ from "/src/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.6_typescript@5.6.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_Nrtgta9p4k from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AxkGiEAlXB from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WzPLfbBOuB from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_k8aIh5lN1k from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Z2cvo5OTYn from "/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.36.0_typescript@5.6.2_vue@3.5.6_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_zbacBAXhj0 from "/src/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_mRe6W6fplg from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.10.0_jiti@2.4.2__io_kyxu2dbt4d7ykc5sjpq5uglshu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_QlwR7wP1tx from "/src/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.36.0_vite@6.2._cnqejhavendafwtf4br7go6efq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_iwzhuZz5jm from "/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.36.0_vite@6.2.3_@types+node@22.13.10_jiti@2.4.2_sass_z24wambqtepb2is3uhmwulejuu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_8Wh3zMWyDB from "/src/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.7_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import siteConfig_S78ToosjXL from "/src/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.6_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_rlw02cSFI7 from "/src/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.6_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_ZaJeucvAvt from "/src/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.6_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_enyVzsrXEm from "/src/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.6_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_deSi0HFFKd from "/src/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.6_typescript@5.6.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_ZcYibVx8Bc from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.36.0_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_qSXBn9WAga from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.36.0_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import vue_awesome_paginate_rvI9ZlY63V from "/src/plugins/vue-awesome-paginate.ts";
export default [
  revive_payload_client_qd6qQR1ezm,
  unhead_gjKQVggGEZ,
  router_7JxBUxWU3o,
  _0_siteConfig_lJzV5AbHuJ,
  payload_client_Nrtgta9p4k,
  navigation_repaint_client_AxkGiEAlXB,
  check_outdated_build_client_WzPLfbBOuB,
  chunk_reload_client_k8aIh5lN1k,
  plugin_vue3_Z2cvo5OTYn,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mRe6W6fplg,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_QlwR7wP1tx,
  plugin_iwzhuZz5jm,
  plugin_client_8Wh3zMWyDB,
  siteConfig_S78ToosjXL,
  inferSeoMetaPlugin_rlw02cSFI7,
  titles_ZaJeucvAvt,
  defaultsWaitI18n_enyVzsrXEm,
  i18n_deSi0HFFKd,
  switch_locale_path_ssr_ZcYibVx8Bc,
  i18n_qSXBn9WAga,
  plugin_wy0B721ODc,
  vue_awesome_paginate_rvI9ZlY63V
]